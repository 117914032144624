import React, { useState, useEffect } from "react";
import { Container, Typography, Box, Stack, Link } from "@mui/material";
import { styled } from '@mui/system';
import { Instagram, WhatsApp } from '@mui/icons-material';

// Estilização dos componentes
const CountdownContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
    [theme.breakpoints.down('sm')]: {
        margin: '10px 0',
    },
}));

const CountdownItem = styled(Box)(({ theme }) => ({
    margin: '0 10px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
        margin: '0 5px',
    },
}));

const Footer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    [theme.breakpoints.down('sm')]: {
        marginTop: '20px',
    },
}));

const SocialIcon = styled(Box)(({ theme }) => ({
    margin: '0 5px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
        margin: '0 3px',
    },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
        marginBottom: '20px',
    },
}));

// Componente principal
const ComingSoon = () => {
    const calculateTimeLeft = () => {
        const difference = +new Date("2024-09-30") - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <Stack
            style={{
                backgroundColor: "black",
                position: 'relative',
                overflow: 'hidden',
                height: '100vh',
                width: "100vw",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            }}
            sx={{
                backgroundImage: {
                    xs: 'url(/woman_4.png)',
                    md: 'url(/woman_3.png)',
                },
            }}
        >
            <Container style={{
                textAlign: 'center',
                color: '#ffffff',
                backgroundColor: 'transparent',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 20px',
            }}>
                <LogoContainer>
                    <img src="logo-infinity.png" alt="Logo" style={{ width: '250px', height: 'auto' }} />
                </LogoContainer>
                <Typography variant="h2" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.75rem' } }}>
                    Coming soon.
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
                    We are currently crafting something singular. Stay tuned.
                </Typography>

                <CountdownContainer>
                    {Object.keys(timeLeft).map((interval) => (
                        <CountdownItem key={interval}>
                            <Typography variant="h3" sx={{ fontSize: { xs: '2rem', md: '3rem' } }}>
                                {timeLeft[interval]}
                            </Typography>
                            <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.75rem', md: '1rem' } }}>
                                {interval}
                            </Typography>
                        </CountdownItem>
                    ))}
                </CountdownContainer>
                <Stack direction="column" spacing={2} sx={{ marginTop: { xs: 5, md: 10 } }}>
                    <Typography variant="subtitle1">Request your appointment:</Typography>

                    <Footer>
                        <SocialIcon onClick={() => {
                            window.open('https://www.instagram.com/dramorenaromano', '_blank');
                        }}>
                            <Instagram style={{ color: '#ffffff' }} />
                        </SocialIcon>
                        <SocialIcon onClick={() => {
                            window.open('https://wa.me/351937249057', '_blank');
                        }}>
                            <WhatsApp style={{ color: '#ffffff' }} />
                        </SocialIcon>
                    </Footer>
                </Stack>

                <Stack direction="column" spacing={2} sx={{ marginTop: 'auto', marginBottom: 2 }}>
                    <Typography variant="caption">
                        Powered by{' '}
                        <Link href="https://mios.pt" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                            MIOS
                        </Link>
                    </Typography>
                </Stack>
            </Container>
        </Stack>
    );
};

export default ComingSoon;
